// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CreditdebitCardPayments from "../../blocks/CreditdebitCardPayments/src/CreditdebitCardPayments";
import Notifications from "../../blocks/Notifications/src/Notifications";
import PromoCodes from "../../blocks/PromoCodes/src/PromoCodes";
import Videos from "../../blocks/Videos/src/Videos";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import CustomisableUserSubscriptions from "../../blocks/CustomisableUserSubscriptions/src/CustomisableUserSubscriptions";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import DocumentOpener from "../../blocks/DocumentOpener/src/DocumentOpener";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import LandingPage from "../../blocks/LandingPage/src/LandingPage";



const routeMap = {
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CreditdebitCardPayments:{
 component:CreditdebitCardPayments,
path:"/CreditdebitCardPayments"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
PromoCodes:{
 component:PromoCodes,
path:"/PromoCodes"},
Videos:{
 component:Videos,
path:"/Videos"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
CustomisableUserSubscriptions:{
 component:CustomisableUserSubscriptions,
path:"/CustomisableUserSubscriptions"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;